<template>
  <div>
    <div v-if="$fetchState.pending || loader">
      <!-- Banner -->
      <div role="status" class="max-w-6xl sm:px-6 lg:px-8 xl:px-0 px-4 mx-auto space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center">
        <div class="w-full">
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
        </div>
        <div class="flex items-center justify-center w-full h-96 bg-gray-300 rounded sm:w-3/5 dark:bg-gray-700">
          <svg class="w-12 h-12 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
        </div>
        <span class="sr-only">Loading...</span>
      </div>

      <!-- Advisory -->
      <div class="max-w-6xl mx-auto sm:px-6 lg:px-8 xl:px-0 px-4">
        <div class="mt-12 space-y-4">
          <router-link :to="localePath('/advisory')" class="font-semibold text-green-800 text-2xl">
            {{ $t('advisory') }}
          </router-link>
          <div class="flex justify-between gap-3 overflow-x-scroll">
            <div v-for="item in 4" :key="item" role="status" class="w-72 p-2 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700">
              <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                <svg class="w-12 h-12 text-gray-200 dark:text-gray-600" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
              </div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Agri-market -->
      <div class="max-w-6xl mx-auto sm:px-6 lg:px-8 xl:px-0 px-4 mb-6">
        <div class="mt-12 space-y-4">
          <router-link :to="localePath('/agri-market')" class="font-semibold text-green-800 text-2xl">
            {{ $t('agri-market') }}
          </router-link>
          <div class="flex justify-between gap-3 overflow-x-scroll">
            <div v-for="item in 5" :key="item" role="status" class="w-64 h-24 flex gap-3 p-2 border border-gray-200 rounded shadow animate-pulse md:p-4 dark:border-gray-700">
              <div class="flex items-center justify-center h-16 w-16 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                <svg class="w-12 h-12 text-gray-200 dark:text-gray-600" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
              </div>
              <div>
                <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-36 mb-4"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              </div>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="$fetchState.error">An error occurred</div>
    <div class="" v-else>
    <div >
      <div class="bg-gradient-to-r from-green-100 to-green-50 relative">
        <swiper
          ref="swiper"
          class="swiper custom-style"
          :options="sliderOption"
        >
          <client-only>
            <swiper-slide v-for="(slide, index) in slides" :key="index">
              <div class="max-w-6xl mx-auto px-4 lg:px-8 xl:px-0">
                <div class="grid lg:grid-cols-2 grid-cols-1 px-0 items-center">
                  <div class="grid content-center">
                    <div class="flex items-center my-5">

                      <img width="20" height="18" :src="'https://bdp.agsat.uz/uploads' + slide.attributes?.logo" alt="Logo images" />
                      <div class="text-gray-800 text-base font-semibold ml-1">
                        <!-- {{ $t('business-development-platform') }} -->
                        {{ slide.attributes?.subtitle }}
                      </div>
                    </div>
                    <div class="lg:text-5xl mb-3 text-2xl font-semibold">
                      <!-- {{ $t('agriculture-business') }}<br /> -->
                      {{ slide.attributes?.title }}
                      <!-- <div class="text-green-800 lg:my-4 my-0">{{ $t('in-karakalpakstan') }}</div> -->
                    </div>
                    <br>
                    <div class="text-gray-500 mr-4">
                      <!-- {{ $t('info-about-platform') }} -->
                      {{ slide.attributes?.description }}
                    </div>
                    <div class="my-5">
                      <button
                        v-if="index == 0"
                        @click="openRegister()"
                        class="text-white text-sm bg-green-700 focus:outline-none rounded-md py-3 px-5"
                      >
                        {{ $t('register') }}
                      </button>
                      <div v-else class="flex gap-4 md:justify-start justify-center">
                        <div class="flex-custom gap-4 my-4 social-btns md:flex-nowrap flex-wrap">
                          <a v-if="slide.attributes.play_market_link" :href="slide.attributes.play_market_link" class="w-44 rounded-md overflow-hidden h-14" target="_blank"><img class="w-full object-cover h-full" src="~/assets/images/playmarket.png" alt="Play market"></a>
                          <a v-if="slide.attributes.appstore_link" :href="slide.attributes.appstore_link" class="w-44 rounded-md overflow-hidden h-14" target="_blank"><img class="w-full object-cover h-full" src="~/assets/images/appstore.jpg" alt="App Store"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <img :src="'https://bdp.agsat.uz/uploads' + slide.attributes?.image" class="rounded-md" alt="home" />
                  </div>
                </div>
              </div>
            </swiper-slide>
          </client-only>
        </swiper>
        <!-- <div class="absolute group top-1/2 lg:flex hidden justify-between -inset-x-9">
          <div
            slot="button-prev"
            class="swiper-button-prev btn shadow-md opacity-0 left-9"
            @click="prev()"
          />
          <div
            slot="button-next"
            class="swiper-button-next btn shadow-md opacity-0 -right-9"
            @click="next()"
          />
        </div> -->
      </div>

      <div class="max-w-6xl mx-auto sm:px-6 lg:px-8 xl:px-0 px-4">
        <div class="mt-12 space-y-4">

          <router-link :to="localePath('/advisory')" class="font-semibold text-green-800 text-2xl">
            {{ $t('advisory') }}
          </router-link>
          <div class="">
            <experts-swiper :experts="experts" />
          </div>
        </div>
        <div class="mt-12 space-y-4">
          <router-link
            :to="localePath('/agri-market')"
            class="font-semibold text-green-800 text-2xl"
            >{{ $t('agri-market') }}</router-link
          >
          <div class="">
            <price-swiper :prices="pricList" />
          </div>
        </div>
        <div class="mt-12 space-y-4">
          <router-link :to="localePath('/e-learning')" class="font-semibold text-gray-700 text-2xl">
            <span class="text-green-800"> {{ $t('e-learning') }}</span>
          </router-link>
          <div class="grid md:grid-cols-3 md:gap-6 gap-0 sm:grid-cols-2 grid-cols-1">
            <div v-for="(video, index) in dataCourses" :key="index" class="mt-6">
              <video-card :data="video" />
            </div>
          </div>
          <router-link
            :to="localePath('/e-learning')"
            class="flex items-center justify-center my-6"
          >
            <button class="text-green-800 focus:outline-none text-sm flex items-center">
              {{ $t('see-all') }}
              <i class="bx bx-right-arrow-alt text-green-800 text-xl ml-1"></i>
            </button>
          </router-link>
        </div>
        <!--      <div class="my-8 space-y-4">-->
        <!--        <router-link-->
        <!--          :to="localePath('/agri-business')"-->
        <!--          class="font-semibold text-green-800 my-4 text-2xl"-->
        <!--        >-->
        <!--          {{ $t('agri-business') }}-->
        <!--        </router-link>-->
        <!--        <div class="grid lg:grid-cols-2 grid-cols-1 lg:mt-12 my-6 lg:space-x-10 space-y-8">-->
        <!--          &lt;!&ndash; <img src="~/assets/images/map.png" alt="" /> &ndash;&gt;-->
        <!--          <yandex-map :coords="getLocations" />-->
        <!--          <div class="relative">-->
        <!--            <div class="text-xl text-gray-800 font-semibold">-->
        <!--              <span class="text-green-800">{{ $t('karakalpakstan') }}</span> - -->
        <!--              {{ $t('didactive-map') }}-->
        <!--            </div>-->
        <!--            <div class="text-gray-500 text-sm leading-6 mt-7">-->
        <!--              {{-->
        <!--                $t(-->
        <!--                  'information-on-agri-business-units-that-help-users-to-access-service-providers-for-establishing-and-operating-business'-->
        <!--                )-->
        <!--              }}-->
        <!--            </div>-->
        <!--            <div class="grid lg:grid-cols-2 grid-cols-1 mt-10 gap-10">-->
        <!--              <div-->
        <!--                v-for="(item, index) in companyCategories"-->
        <!--                :key="index"-->
        <!--                @click="moveToAgriBusiness(item)"-->
        <!--              >-->
        <!--                <div class="flex items-center cursor-pointer">-->
        <!--                  <img-->
        <!--                    :src="-->
        <!--                      item.attributes && item.attributes.icon-->
        <!--                        ? $tools.getFileUrl(item.attributes.icon)-->
        <!--                        : require(`~/assets/images/district.png`)-->
        <!--                    "-->
        <!--                    class="w-6 object-contain"-->
        <!--                  />-->
        <!--                  <p class="text-base font-semibold text-gray-800 ml-4">-->
        <!--                    {{ item.attributes.name }}-->
        <!--                  </p>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <router-link-->
        <!--              :to="localePath('/agri-business')"-->
        <!--              class="lg:absolute bottom-0 flex justify-center lg:mt-0 mt-6"-->
        <!--            >-->
        <!--              <button-->
        <!--                class="text-white focus:outline-none py-3 px-7 font-medium bg-green-700 rounded-md text-sm"-->
        <!--              >-->
        <!--                {{ $t('discover-more') }}-->
        <!--              </button>-->
        <!--            </router-link>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </div>-->
        <div class="mt-12 space-y-4">
          <router-link :to="localePath('/news')" class="font-semibold text-gray-700 text-2xl">
            <span class="text-green-800">{{ $t('news') }}</span>
          </router-link>
          <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
            <NewsSwiper :news="dataNews" class="col-span-3" />
          </div>
          <router-link
            :to="localePath('/news')"
            class="flex items-center justify-center my-4"
          >
            <button class="text-green-800 focus:outline-none text-sm flex items-center">
              {{ $t('see-all') }}
              <i class="bx bx-right-arrow-alt text-green-800 text-xl ml-1"></i>
            </button>
          </router-link>
        </div>
        <div class="mt-12 space-y-4">
          <div class="font-semibold text-gray-700 text-2xl">
            {{ $t('frequently') }} <span class="text-green-800">{{ $t('asked-questions') }}</span>
          </div>
          <div class="grid lg:grid-cols-2 grid-cols-1 gap-6">
            <div>
              <div class="text-gray-500 leading-7 text-sm">
                {{
                  $t(
                    'below-you-will-find-answers-to-the-questions-we-get-asked-most-about-using-the-platfrom'
                  )
                }}
              </div>
              <div>
                <div v-for="(faq, index) in dataFaqs" :key="index">
                  <faq :data="faq" />
                </div>
              </div>
            </div>
            <img src="~/assets/images/homeSmall.jpg" class="rounded-md" alt="home" />
          </div>
        </div>
        <div class="mt-12 space-y-4">
          <!--        <div class="">-->
          <!--          <partners-swiper :partners="dataPartners" />-->
          <!--        </div>-->
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import registeModal from '../../components/modals/register.vue'
import expertsSwiper from '~/components/swipers/experts-swiper.vue'
import NewsSwiper from '~/components/swipers/news-swipers/new-swipers'
import PriceSwiper from '~/components/swipers/price-swiper.vue'
import PartnersSwiper from '~/components/swipers/partners-swiper.vue'
// import YandexMap from '~/components/core/yandex-map.vue'
import Faq from '~/components/Faq.vue'
import MainLoading from '~/components/main-loading'
export default {
  auth: false,
  components: {
    MainLoading,
    // YandexMap,
    expertsSwiper,
    PriceSwiper,
    NewsSwiper,
    PartnersSwiper,
    Faq,
  },
  props: {},
  data() {
    return {
      video: {},
      loading: false,
      experts: [],
      pricList: [],
      slides: [],
      settings: {
        apiKey: 'b8d77dd9-8477-4216-ad67-5074082c24a6',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1',
      },
      companyCategories: [],
      sliderOption: {
        direction: 'horizontal',
        loop: true,
        autoplay: {
          delay: 6000
        },
        pagination: {
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 6,
          },
          410: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          350: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      items: [
        {
          image: 'district',
          title: 'District Hokimiyat',
        },
        {
          image: 'bank',
          title: 'Banks',
        },
        {
          image: 'terminal',
          title: 'Terminal stations',
        },
        {
          image: 'railway',
          title: 'Railway stations',
        },
        {
          image: 'refrigerator',
          title: 'Refrigerators',
        },
        {
          image: 'government',
          title: 'Government branches',
        },
        {
          image: 'farmers',
          title: 'Farmers unions',
        },
        {
          image: 'supply',
          title: 'Supply points',
        },
      ],
      dataTitle: {
        en: 'Post',
        ru: 'Пост',
        uz: 'post',
        kaa: 'Post Kaa',
      },
      loader: false
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.loggedIn,
      currentUser: (state) => state.auth.user,
      userConnection: (state) => state.socket.userConnection,
      userConnectionStatus: (state) => state.socket.userConnectionStatus,
    }),
    ...mapGetters([
      'dataCourses',
      'dataUsers',
      'dataFaqs',
      // 'dataPartners',
      'getLocations',
      'dataNews',
    ]),
  },
  fetchOnServer: true,
  async fetch() {
    // this.$fetchState.pending = true
    await this.$store
      .dispatch('getSliders', {
        locale: this.$i18n.locale,
        fields: 'logo,subtitle,title,description,play_market_link,appstore_link,image',
        sort: 'id:desc'
      })
      .then(res => {
        this.slides = res

      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
    await this.$store
      .dispatch('getPricelists', {
        populate: 'district, district.localizations, product,product.localizations',
        fields: 'avg,min,max',
        locale: this.$i18n.locale,
        'pagination[page]': 1,
        'pagination[pageSize]': 12,
        filters: {
          max: {
            $gt: 0,
          },
          min: {
            $gt: 0,
          },
        },
      })
      .then((res) => {
        this.pricList = res.map((el) => {
          return {
            id: el.id,
            attributes: {
              ...el.attributes,
              district: {
                data: {
                  attributes: {
                    name:
                      el.attributes.district.data.attributes.locale === this.$i18n.locale
                        ? el.attributes.district.data.attributes.name
                        : this.checkProductLocalizations(
                            el.attributes.district.data.attributes.localizations.data,
                            this.$i18n.locale
                          ),
                  },
                },
              },
              product: {
                data: {
                  attributes: {
                    image: el.attributes.product.data.attributes.image,
                    name:
                      el.attributes.product.data.attributes.locale === this.$i18n.locale
                        ? el.attributes.product.data.attributes.name
                        : this.checkProductLocalizations(
                            el.attributes.product.data.attributes.localizations.data,
                            this.$i18n.locale
                          ),
                  },
                },
              },
            },
          }
        })
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
    await this.$store
      .dispatch('getUsers', {
        link: '/users',
        query: {
          populate:
            'additionalinfo,additionalinfo.localization,consultantcategory, consultantcategory.localization,agrocultureareas, agrocultureareas.localization',
          locale: this.$i18n.locale,
          'filters[$and][0][confirmed]': true,
          'filters[$and][0][role][id]': 4,
          fields: 'avatar'
          // fields: 'avatar,middlename,name,surname,activitytypes,otherarea,additionalinfo,agrocultureareas,env_otherarea'
        },
      })
      .then((res) => {
        this.experts = res.users
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
    //

    await this.$store
      .dispatch('getFaqs', {
        populate: '*',
        locale: this.$i18n.locale,
        fields: 'question,answer'
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
    await this.$store
      .dispatch('getCourses', {
        populate: '*',
        locale: this.$i18n.locale,
        sort: ['createdAt:desc'],
        'pagination[page]': 1,
        'pagination[pageSize]': 3,
        filters: {
          status: {
            title: this.dataTitle[this.$i18n.locale],
          },
        },
        fields: 'image,thumbnail,publish_date,title,description'
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
    // await this.getPartners()
    // await this.getCompanies()
    // await this.getCompanycategories()
    await this.getNews()
    // await this.getAbout()

  },
  methods: {
    async getNews () {
      try {
        const data =  await this.$store
          .dispatch('getNews', {
            populate: '*',
            locale: this.$i18n.locale,
            pagination: {
              page: 1,
              pageSize: 10
            },
            'sort[0][createdAt]': 'DESC',
            fields: 'title,description,createdAt,image'
          })
        return data
      } catch (err) {}
    },
    async getCompanies () {
     try {
       const data =   await this.$store
         .dispatch('getCompanies', {
           populate: '*',
           locale: this.$i18n.locale,
         })

         .catch((error) => {
           this.$sentry.captureException(error)
         })
           this.$store.dispatch('setCompanies', data)
       return data
     } catch (err) {}
    },
    async getCompanycategories () {
      try {
        const data = await this.$store
          .dispatch('getCompanycategories', {
            populate: '*',
            locale: this.$i18n.locale,
          })
          // .then((res) => {
            this.companyCategories = data
          // })
        return data
      } catch (err) {}
    },
    async getPartners () {
      try {
        const data=   await this.$store
          .dispatch('getPartners', {
            populate: '*',
            locale: this.$i18n.locale,
          })
        return data
      } catch (err)  {}
    },
    // async getAbout () {
    //   try {
    //     const data=  await this.$store
    //       .dispatch('getAbout', {
    //         locale: this.$i18n.locale
    //       })
    //       // .then(res => {
    //         console.log("getAbout", data)
    //       // })
    //       // .catch((error) => {
    //       //   this.$sentry.captureException(error)
    //       // })
    //
    //   } catch (err){
    //
    //   }
    // },
    prev() {
      this.$refs.swiper.$swiper.slidePrev()
    },
    next() {
      this.$refs.swiper.$swiper.slideNext()
    },
    checkProductLocalizations(item, lang) {
      let _ = item.find((el) => el.attributes.locale === lang).attributes.name
      return _
    },
    moveToAgriBusiness(item) {
      let _query = {
        category: item.id,
      }
      this.$router.push({
        path: this.localePath('/agri-business'),
        query: this.$tools.emptyObject(_query),
      })
    },
    openRegister() {
      this.$emit('close')
      this.$modal.show(
        registeModal,
        { status: 'register' },
        {
          height: 'auto',
          maxWidth: 700,
          width: window.innerWidth <= 650 ? window.innerWidth - 10 : 650,
          scrollable: true,
        }
      )
    },
  },

}
</script>


<style>
  .flex-custom {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custom-style > div {
    align-items: center !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    --swiper-theme-color: #059669;
    background-color: white;
    padding: 22px;
    color: #059669 !important;
    fill: #059669 !important;
    stroke: #059669 !important;
    border-radius: 100%;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 13px;
  }
  .experts:hover .btn {
    opacity: 1;
  }
</style>
